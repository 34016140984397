import _ from 'lodash';
import moment from 'moment';
import { fromJS } from 'immutable';
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Spin,
  Switch,
} from 'antd';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { addConstituent, fetchTags, patchConstituent } from '../../../actions';

import { connectForm } from '../../form';

import { ErrorsList } from '../../ErrorsList';
import { ConstituentTagList } from '../../ConstituentTagList';
import {
  fullTagLabel, reduceTagsHierarchy,
} from '../../../utils';
import { getPhoneNumberField } from '../../fields/PhoneNumberField';
import getDateOfBirthField, { generateBirthDateTooltip } from '../../fields/DateOfBirthField';
import { IconDefinedTerm, PortalChild } from '../../elements';

const LABEL_COL_WIDTH = 5;
const WRAPPER_COL_WIDTH = 24 - LABEL_COL_WIDTH;

const NAME_ITEM_LAYOUT = {
  wrapperCol: {
    sm: { span: 19 },
    md: { span: 19 },
    lg: {
      span: 17,
      offset: 0,
    },
  },
  labelCol: {
    sm: { span: 5 },
    md: { span: 5 },
    lg: {
      span: 5,
      offset: 2,
    },
  },
};

const ADD_ALT_NAME_ITEM_LAYOUT = {
  wrapperCol: {
    sm: { span: 18 },
    md: { span: 18 },
    lg: {
      span: 1,
      offset: 0,
    },
  },
  labelCol: {
    sm: { span: 5 },
    md: { span: 5 },
    lg: {
      span: 18,
      offset: 0,
    },
  },
};

const getProcessedData = data => {
  const bDate = data.birthDate;
  const birthDate = (bDate && bDate !== null) ? bDate.format('YYYY-MM-DD') : null;
  const alternateName = data.addAlternateName ? data.alternateName : null;
  const positionNumber = data.positionNumber;
  return { ...data, birthDate, alternateName, positionNumber };
};

class ExternalLinkFauxInput extends PureComponent {
  render() {
    const { template, value } = this.props;
    if (!(value && template)) return null;
    const link = template.replace(/\[ID\]/g, value);
    return <a target="_blank" rel="noopener noreferrer" href={link}>{link}</a>;
  }
}

@connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    organization: state.doc.getIn(['authMr', 'constituent', 'organization'], null),
    orgRootTag: state.doc.getIn(['tagsMr', 'tree', 'organization'], null),
    allTags: state.doc.getIn(['tagsMr', 'items'], fromJS([])).toJS(),
  }),
  {
    fetchTags,
    addConstituent,
    patchConstituent,
  },
)
@connectForm(({
  isNew,
  constituent,
  organizationId,
  addConstituent,
  patchConstituent,
}) => ({
  httpGroup: isNew ? 'addConstituent' : 'patchConstituent',
  postAction: isNew
    ? data => addConstituent({
      organization: {
        id: organizationId,
      },
      ...getProcessedData(data),
    })
    : data => patchConstituent(constituent.get('id'), getProcessedData(data)),
  transformToServer: d => ({
    ...d,
    directTags: d.directTags.map(t => ({ id: t })),
  }),
}))
class ConstituentDetailsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAlternateName: false,
      showAlternateNameWarning: false,
    };
  }

  handleAddAlternateNameChange = checked => {
    this.setState({ showAlternateName: checked });
    const alternateName = this.props.constituent && this.props.constituent.get('alternateName', null);
    if (!checked) this.props.form.setFieldsValue({ alternateNumber: null });
    if (alternateName && !checked) {
      this.setState({ showAlternateNameWarning: true });
    } else {
      this.setState({ showAlternateNameWarning: false });
    }
  };

  render() {
    const {
      constituent,
      form,
      constituentId,
      organizationId,
      allTags,
      onCancel,
      saveInProgress,
      handleSubmit,
      isNew,
      organization,
    } = this.props;

    const { showAlternateName, showAlternateNameWarning } = this.state;

    const { getConnectedFieldDecorator } = form;

    const { externalIdEnabled, externalLinkTpl, managerEmailEnabled } = organization ? organization.toJS() : {};
    const formItemProps = {
      labelCol: { span: LABEL_COL_WIDTH },
      wrapperCol: { span: WRAPPER_COL_WIDTH },
    };

    const orgRootTag = this.props.orgRootTag ? this.props.orgRootTag.toJS() : null;
    const orgTag = ((orgRootTag || {}).children || []).find(
      tag => tag.role === `organization.${organizationId}`,
    );

    const tagsListJs = orgTag ? reduceTagsHierarchy(orgTag, false) : [];

    // Map the constituent tag list from the global tag list
    const constituentDirectTagsRaw = constituent ? constituent.get('directTags', null) : null;
    const directTags = (constituentDirectTagsRaw ? constituentDirectTagsRaw.toJS() : [])
      .map(({ id }) => allTags.find(tag => tag.id === id));

    const selectableTags = tagsListJs ? tagsListJs.map(tag => ({
      optValue: fullTagLabel(tag, { joiner: ' ' }),
      optLabel: fullTagLabel(tag),
      ...tag,
    })) : [];

    const selectedTags = directTags.filter(tag => tag && tag.editable);
    const appliedNonEditableTags = directTags.filter(tag => tag && !tag.editable);

    if (!isNew && constituentId && !constituent) {
      return <Spin spinning={true} />;
    }

    const initialData = constituent ? constituent.toJSON() : {
      active: true,
    };

    let emailWarning = null;
    if (constituent) {
      const userEmail = constituent.getIn(['user', 'email']);
      const constituentEmail = constituent.get('email');
      if (userEmail && userEmail !== constituentEmail) {
        emailWarning = (<div>Login email is different. Use <strong>{constituent.getIn(['user', 'email'])}</strong></div>);
      }
    }

    const alternateName = (constituent && constituent.get('alternateName', null)) || showAlternateName;

    initialData.directTags = !selectedTags ? [] : selectedTags.map(tag => tag.id);

    return (<Form ref="form" onSubmit={this.handleSubmit}>
      <ErrorsList errors={this.props.globalErrors} />
      <Form.Item
        label="Active"
        extra="Is this person actively working for your organisation?"
        {...formItemProps}
      >
        {getConnectedFieldDecorator('active', {
          initialValue: initialData.active,
          valuePropName: 'checked',
        })(
          <Switch />,
        )
        }
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={18}>
          <Form.Item
            label={(<IconDefinedTerm definition="Needs to match name that the person's accreditation is issued under">
          Full name
            </IconDefinedTerm>)}
            {...formItemProps}
            {...NAME_ITEM_LAYOUT}
          >
            {getConnectedFieldDecorator('fullName', {
              initialValue: initialData.fullName,
              backendValidateLinks: ['email', 'mobileNumber'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Full name is required',
                },
              ],
            })(
              <Input />,
            )
        }
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6}>
          <Form.Item
            label={(<IconDefinedTerm definition="This person has an alternate legal name (maiden, married, legally changed)">
        Alternate name
            </IconDefinedTerm>)}
            {...formItemProps}
            {...ADD_ALT_NAME_ITEM_LAYOUT}
          >
            {getConnectedFieldDecorator('addAlternateName', {
              initialValue: !!alternateName,
              valuePropName: 'checked',
              onChange: this.handleAddAlternateNameChange,
            })(
              <Switch />,
            )
        }
          </Form.Item>
        </Col>
      </Row>
      {showAlternateNameWarning && (
      <span style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
              Disabling the 'Alternate name' switch will remove the existing alternate name upon saving
      </span>
      )}
      {
        showAlternateName && (
          <strong style={{ color: '#C88242', display: 'flex', justifyContent: 'center' }}>
                When adding alternate name, you confirm diligence checks on correctness and evidence to support
          </strong>
        )
      }
      { alternateName && <Form.Item
        label={'Alternate name'}
        {...formItemProps}
                         >
        {getConnectedFieldDecorator('alternateName', {
          initialValue: initialData.alternateName,
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Alternate name is required',
            },
          ],
        })(
          <Input />,
        )
        }
      </Form.Item>}
      <Spin spinning={_.isNil(orgRootTag)}>
        <Form.Item
          label={(<IconDefinedTerm definition="Tags enable admin users to organise people into groups.">Tags</IconDefinedTerm>)}
          extra={(
            <ConstituentTagList selectedTags={fromJS(appliedNonEditableTags)} />
          )}
          {...formItemProps}
        >
          {getConnectedFieldDecorator('directTags', {
            initialValue: initialData.directTags,
          })(
            <Select
              mode="multiple"
              placeholder="No tags"
              // ref="tags"
              key={selectedTags ? 'withValues' : 'noValues'}
              optionFilterProp="filtervalue"
            >
              {selectableTags.map(tag => (<Select.Option
                key={tag.id}
                value={tag.id}
                filtervalue={tag.optValue}
                                          >
                { tag.optLabel}
              </Select.Option>))}
            </Select>,
          )}
        </Form.Item>
      </Spin>
      { /* TODO read only if has a user */}
      <Form.Item
        label="Email address"
        extra={emailWarning}
        {...formItemProps}
      >
        {getConnectedFieldDecorator('email', {
          initialValue: initialData.email || '',
          backendValidateLinks: ['fullName', 'mobileNumber'],
          validate: [{
            trigger: 'onBlur',
            rules: [{
              type: 'email',
              message: 'Email address is invalid',
            }],
          }],
        })(
          <Input />,
        )
        }
      </Form.Item>
      <Form.Item
        label="Mobile number"
        {...formItemProps}
      >
        {getPhoneNumberField({
          initialValue: initialData.mobileNumber || '',
          fieldName: 'mobileNumber',
          fieldText: 'Mobile number',
          rules: [
            { validator: this.backendErrorsValidator },
          ],
          form,
        })}
      </Form.Item>
      <Form.Item label="Alternate number" {...formItemProps}>
        {getPhoneNumberField({
          initialValue: initialData.alternateNumber || '',
          fieldName: 'alternateNumber',
          fieldText: 'Alternate number',
          rules: [
            { validator: this.backendErrorsValidator },
          ],
          form,
        })}
      </Form.Item>
      <Form.Item
        label={(<IconDefinedTerm definition={generateBirthDateTooltip(initialData.birthDate)}>
          Date of Birth
        </IconDefinedTerm>)}
        {...formItemProps}
      >
        {getConnectedFieldDecorator('birthDate', {
          initialValue: (initialData.birthDate && initialData.birthDate !== null) ? moment(initialData.birthDate) : null,
        })(
          getDateOfBirthField({ initialValue: initialData.birthDate, yearsToRestrict: 10, disabled: false }),
        )
        }
      </Form.Item>

      { externalIdEnabled ? [
        <Form.Item
          label="External ID"
          key="id"
          {...formItemProps}
        >
          {getConnectedFieldDecorator('externalId', {
            initialValue: initialData.externalId || '',
          })(
            <Input />,
          )
          }
        </Form.Item>,
        externalLinkTpl && form.getFieldValue('externalId')
          ? <Form.Item label="External Link" key="link" {...formItemProps}>
            {getConnectedFieldDecorator('externalId', {
            })(
              <ExternalLinkFauxInput template={externalLinkTpl} />,
            )
            }
          </Form.Item> : null,
      ] : null}
      { managerEmailEnabled
        && <Form.Item
          label="Manager email"
          {...formItemProps}
           >
          {getConnectedFieldDecorator('manager_email', {
            initialValue: initialData.managerEmail || '',
            backendValidateLinks: ['fullName', 'mobileNumber'],
            validate: [{
              trigger: 'onBlur',
              rules: [{
                type: 'email',
                message: 'Email address is invalid',
              }],
            }],
          })(
            <Input />,
          )
          }
        </Form.Item>
      }
      <Form.Item label="Position Number" {...formItemProps}>
        {getConnectedFieldDecorator('positionNumber', {
          initialValue: initialData.positionNumber || '',
        })(
          <Input placeholder="Enter position number" />,
        )}
      </Form.Item>

      <PortalChild portal={this.props.footerRef}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          // Can't use HTML form submit as this is rendered outside the
          // HTML form
          type="primary"
          loading={saveInProgress}
        >Save</Button>
      </PortalChild>
    </Form>);
  }
}

export {
  ConstituentDetailsForm,
};
