import React from 'react';
import { Result } from 'antd';
import { parseSearch } from '../../../utils';
import { SmartRecruiters } from './SmartRecruiters';


const IntegrationDetailComponent = props => {
  const { appName } = props;

  if (appName === 'smartRecruiters') {
    return <SmartRecruiters {...props} />;
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};

const appNameFromRoute = props => {
  if (props.match.params.appName) {
    return props.match.params.appName;
  }
  return parseSearch(props.location.search).appName;
};

export const IntegrationDetailPage = props => (
  <IntegrationDetailComponent appName={appNameFromRoute(props)} queryParams={parseSearch(props.location.search)} {...props} />
);
