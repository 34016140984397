import React, { Component } from 'react';
import {
  Button, Checkbox, Icon, Spin, Result, message,
} from 'antd';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ohoLogo from '../../../logos/logo_dark.png';
import SmartRecruitersIcon from '../../../logos/smart_recruiters_icon.png';
import SmartRecruitersLogo from '../../../logos/smart_recruiters_logo.png';
import { withContentLayout } from '../../layout/Layout';
import * as actions from '../../../actions';

@withRouter
@withContentLayout
@connect(state => ({
  smartRecruitersAccessData: state.doc.getIn(['http', 'validateSmartRecruitersAccess', 'data'], null),
  smartRecruitersAccessFailed: state.doc.getIn(['http', 'validateSmartRecruitersAccess', 'failed'], false),
  smartRecruitersAccessInProgress: state.doc.getIn(['http', 'validateSmartRecruitersAccess', 'inProgress'], false),

  smartRecruitersAuthorizeData: state.doc.getIn(['http', 'smartRecruitersAuthorize', 'data'], null),
  smartRecruitersAuthorizeFailed: state.doc.getIn(['http', 'smartRecruitersAuthorize', 'failed'], false),
  smartRecruitersAuthorizeInProgress: state.doc.getIn(['http', 'smartRecruitersAuthorize', 'inProgress'], false),
}), actions)
class SmartRecruiters extends Component {
  constructor(props) {
    super(props);
    const queryParams = props.queryParams || {};
    this.state = {
      authorized: false,
      scopeAgreed: true,
      companyId: queryParams.companyId || null,
      redirectUrl: queryParams.redirect || null,
    };
  }

  componentDidMount() {
    const { companyId, redirectUrl } = this.state;

    if (!companyId || !redirectUrl) {
      message.error('Missing required parameters: company id or redirect url.');
    } else {
      this.props.validateSmartRecruitersAccess(companyId, redirectUrl);
    }
  }

  handleAuthorization = async () => {
    const { scopeAgreed, companyId, redirectUrl } = this.state;

    if (!companyId || !redirectUrl) {
      message.error('Cannot authorize. Missing required parameters: company id or redirect url.');
      return;
    }

    if (scopeAgreed) {
      try {
        const decodedRedirectUrl = decodeURIComponent(redirectUrl);
        const response = await this.props.smartRecruitersAuthorize(companyId, decodedRedirectUrl);
        const { redirectUrl: receivedRedirectUrl } = response.data;

        if (receivedRedirectUrl) {
          if (receivedRedirectUrl.startsWith('https://')) {
            window.location.href = receivedRedirectUrl;
          } else {
            message.error('Failed to redirect. The provided URL is invalid.');
          }
        } else {
          this.setState({ authorized: true });
          message.success('Integration has been successfully authorized.');
        }
      } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message)
          || 'Authorization failed. Please try again.';
        message.error(errorMessage);
      }
    }
  };

  render() {
    const { authorized, companyId, redirectUrl } = this.state;
    const {
      smartRecruitersAccessData,
      smartRecruitersAccessInProgress,
      smartRecruitersAccessFailed,
      smartRecruitersAuthorizeInProgress,
    } = this.props;

    const smartRecruitersAccessDataJS = smartRecruitersAccessData ? smartRecruitersAccessData.toJSON() : {};
    const { scopes } = smartRecruitersAccessDataJS;

    if (!companyId || !redirectUrl) {
      return (
        <Result
          status="403"
          title="Missing Parameters"
          subTitle="Required parameters are missing: company id and/or redirect url. Please check your settings and try again."
        />
      );
    }

    if (smartRecruitersAccessInProgress) {
      return <Spin />;
    }
    if (smartRecruitersAccessFailed) {
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      );
    }
    return (
      <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <img
            src={SmartRecruitersLogo}
            style={{
              maxHeight: '20px',
              marginBottom: '10px',
            }}
            alt="Smart Recruiter"
          />
          <h2 style={{ marginTop: 0, marginBottom: '7px' }}>Integration</h2>
        </div>

        <p style={{ marginBottom: '20px', textAlign: 'center' }}>
          Before proceeding with the Smart Recruiter integration, please review the scope details and authorize below.
        </p>

        <div
          style={{
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '15px',
            marginBottom: '20px',
            background: '#ffffff',
          }}
        >
          <h3 style={{ marginTop: 0 }}>Integration Scope</h3>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {scopes
              && scopes.map(scope => (
                <div style={{ display: 'flex', alignItems: 'center' }} key={scope}>
                  <Checkbox checked disabled style={{ marginRight: '10px' }} />
                  <span>{scope}</span>
                </div>
              ))}
          </div>
        </div>

        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <img
            src={ohoLogo}
            style={{
              maxHeight: '50px',
              minHeight: '40px',
              marginRight: '10px',
            }}
            alt="Oho Logo"
          />
          <Icon type="swap" style={{ fontSize: '24px', color: '#000', marginRight: '10px' }} />
          <img
            src={SmartRecruitersIcon}
            style={{
              maxHeight: '50px',
              minHeight: '40px',
            }}
            alt="Smart Recruiters Logo"
          />
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={this.handleAuthorization}
            loading={smartRecruitersAuthorizeInProgress}
            style={{ width: '100%' }}
          >
            {authorized ? 'Authorized' : 'Authorize Integration'}
          </Button>
        </div>
        {authorized && (
          <p style={{ color: 'green', textAlign: 'center', marginTop: '10px' }}>
            Integration has been successfully authorized.
          </p>
        )}
      </div>
    );
  }
}


export { SmartRecruiters };
