import { genericAjaxDispatch, simpleAjaxDispatch } from './http';

export function fetchNswStandaloneInfo() {
  return simpleAjaxDispatch(
    '/admin/nsw/standalone/info',
    'fetchNswStandaloneInfo',
  );
}

export function fetchNswStandaloneNextBatch(batchSize, orgId) {
  return simpleAjaxDispatch(
    `/admin/nsw/standalone/next?org=${orgId}&count=${batchSize}`,
    'fetchNswStandaloneNext',
  );
}

export function updateStandaloneImportStatus(correlationId, data) {
  return { type: 'UPDATE_STANDALONE_IMPORT_STATUS', correlationId, ...data };
}

export function clearStandaloneImportAction() {
  return { type: 'CLEAR_STANDALONE_IMPORT' };
}

export function clearStandaloneImport() {
  return dispatch => dispatch(clearStandaloneImportAction());
}


export function pushStandaloneImportCompletion(correlationId) {
  return { type: 'PUSH_STANDALONE_IMPORT_COMPLETION', correlationId };
}

const IMPORT_URL_BASE = '/upload/bulk_import/nsw/standalone';
export function uploadStandaloneResult(file, organizationId) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    genericAjaxDispatch(
      dispatch, getState, IMPORT_URL_BASE,
      'uploadStandaloneResultAuthorize',
      {
        method: 'POST',
        json: { organization_id: organizationId, filename: file.name, type: file.type },
        responseSnake: false,
      },
    ).then(
      authAction => {
        if (authAction.data._errors) return reject(authAction.data._errors)
        const filename = authAction.data.filename;
        const type = authAction.data.filetype;
        const formData = new FormData();
        Object.entries(authAction.data.upload.fields).forEach(
          ([key, value]) => formData.append(key, value),
        );
        formData.append('Content-Disposition', `attachment; filename="${filename}"`);
        formData.append('Content-Type', type);
        formData.append('file', file);

        genericAjaxDispatch(
          dispatch, getState,
          authAction.data.upload.url,
          'uploadStandaloneResult',
          {
            method: 'POST',
            body: formData,
            auth: false,
            meta: authAction.data,
          },
        ).then(resolve, reject);
      }, reject,
    );
  });
}

export function startStandaloneResultProcessing(organizationId, bucket, key) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    genericAjaxDispatch(
      dispatch, getState, '/admin/nsw/standalone/start',
      'startStandaloneResultProcessing',
      {
        method: 'POST',
        json: { organization_id: organizationId, bucket, key },
        responseSnake: false,
      },
    ).then(resolve, reject);
  });
}