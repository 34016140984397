import _ from 'lodash';
import {
  Alert, Button, Form, Input, Progress, Spin,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

const BG_CHECK_STATUS = {
  failed: 'failed',
  finished: 'finished',
  loading: 'loading',
  started: 'started',
  running: 'running',
  waiting: 'waiting',
  syncing: 'syncing',
};

@Form.create()
class NDWSSyncOTPComponent extends PureComponent {
  handleNDWSOTP = async form => {
    form.validateFields(async (err, values) => {
      if (err) return;
      await this.props.handleSubmit(values.otp);
    });
  }

  componentDidMount() {
    this.props.setOtpForm(this.props.form);
  }

  render() {
    const { form } = this.props;

    const handleKeyUp = evt => {
      if (evt.key === 'Enter') this.handleNDWSOTP(form);
    };

    return (
      <Form layout="vertical" style={{ paddingTop: '10px' }} >
        <p>Please enter your one-time password, once you have received it.</p>
        <Form.Item label="One-Time Password:">
          {form.getFieldDecorator('otp', {
            rules: [{ required: true, message: 'Please enter your one-time password, once you have received it' }],
          })(<Input onKeyUp={handleKeyUp} />)}
        </Form.Item>
      </Form>
    );
  }
}

export class NdwsSyncContentComponent extends PureComponent {
  state = {
    isLoading: false,
    errorMessage: '',
    pollerStatus: '',
    pollerMessage: '',
    isOhoManaged: false,
  }

  constructor(props) {
    super(props);
    this.ndwsPollerRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchOrganizationSettings();
    this.checkStatus();
  }

  componentWillUnmount() {
    clearInterval(this.ndwsPollerRef);
    this.props.updateNDWSSyncStatusToDefault();
    this.setState({
      isLoading: false,
      errorMessage: '',
      pollerStatus: '',
      pollerMessage: '',
      isOhoManaged: false,
    });
  }

  handleStartSync = async () => {
    this.setState({ isLoading: true });
    await this.props.startNDWSSync();
    this.setState({ isLoading: false });
  }

  checkStatus = async () => {
    this.setState({ isLoading: true });
    await this.props.checkNDWSSyncStatus();
    this.setState({ isLoading: false });
    this.ndwsPollerRef = setInterval(async () => {
      this.props.checkNDWSSyncStatus();
    }, 5000);
  }

  handleSubmitOTP = async input => {
    this.setState({ isLoading: true, otpError: '' });
    const { failed } = await this.props.postNDWSOtp(input, 'ndws-sync');
    if (failed) {
      this.setState({ isLoading: false, otpError: 'Unknown error' });
    } else {
      this.setState({ isLoading: false, otpSubmitted: true });
    }
  }

  getPercentage = () => {
    try {
      const [count, total] = this.state.pollerMessage.split('/').map(Number);
      return (count / total) * 100;
    } catch (error) {
      return 0;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { settings, syncNDWSResponse } = this.props;
    const status = _.get(syncNDWSResponse, ['message', 'status'], '');
    const message = _.get(syncNDWSResponse, ['message', 'message'], '');

    const { providers } = (settings && settings.toJSON()) || {};
    const { ohoManagedCreds } = (providers  && providers.ndws) || {};
    this.setState({ isOhoManaged: !!ohoManagedCreds });

    let error;
    if (status === BG_CHECK_STATUS.failed) error = message;

    this.setState({ pollerStatus: status, pollerMessage: message, errorMessage: error });

    // stop polling if process has succeeded
    if (status === BG_CHECK_STATUS.finished || status === BG_CHECK_STATUS.failed) clearInterval(this.ndwsPollerRef);
  }

  syncNotAllowed = () => (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
      <p>This sync process is currently managed by Oho.</p>
    </div>
  )

  render() {
    if (this.state.isOhoManaged) return this.syncNotAllowed();

    if (this.state.isLoading || this.state.pollerStatus === BG_CHECK_STATUS.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
          <Spin />
        </div>
      );
    }

    if (!this.state.pollerStatus || this.state.pollerStatus === BG_CHECK_STATUS.finished || this.state.pollerStatus === BG_CHECK_STATUS.failed) {
      return (
        <>
          {this.state.pollerStatus === BG_CHECK_STATUS.finished && <Alert message={'NDWS sync process completed!'} type="success" showIcon />}
          {this.state.pollerStatus === BG_CHECK_STATUS.failed && this.state.errorMessage && <div style={{ display: 'flex', color: 'red' }}>
            <strong>{this.state.errorMessage}</strong>
          </div>
          }
          <p style={{ paddingTop: '10px' }}>Would you like to start NDWS sync process?</p>
          <div style={{ paddingTop: '25px', paddingBottom: '25px', display: 'flex', justifyContent: 'center' }}>
            <Button onClick={this.handleStartSync} >Start sync process</Button>
          </div>
        </>
      );
    }

    if (this.state.pollerStatus === BG_CHECK_STATUS.started || this.state.pollerStatus === BG_CHECK_STATUS.running) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '25px', paddingBottom: '25px', textAlign: 'center',
        }}
        >
          <p><strong style={{ color: 'gray' }}>Status</strong>: <strong style={{ color: 'green' }}>{this.state.pollerMessage}</strong></p>
          <Spin />
        </div>
      );
    }

    if (this.state.pollerStatus === BG_CHECK_STATUS.waiting) {
      if (this.state.isOhoManaged) {
        return (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
              <p>Waiting for service to receive and submit the otp</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
              <Spin />
            </div>
          </div>
        )
      } else {
        return <NDWSSyncOTPComponent setOtpForm={ form => this.props.setOtpForm(form)} handleSubmit={input => this.handleSubmitOTP(input)} />;
      }
    }

    if (this.state.pollerStatus === BG_CHECK_STATUS.syncing) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '25px', paddingBottom: '25px', textAlign: 'center',
        }}
        >
          <p><strong style={{ color: 'gray', marginRight: '5px' }}>Processing...</strong><strong style={{ color: 'green' }}>{this.state.pollerMessage}</strong></p>
          <Progress
            percent={this.getPercentage()}
            status={'success'}
            showInfo={false}
          />
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
        <Spin />
      </div>
    );
  }
}

export const NdwsSyncContent = connect(
  (state, props) => ({
    syncNDWSResponse: state.doc.getIn(['ndwsSync'], false),
    settings: state.doc.getIn(['http', 'fetchOrganizationSettings', 'data', 'settings'], null),
  }),
  actions,
)(NdwsSyncContentComponent);
