import { simpleAjaxDispatch } from './http';

export function validateSmartRecruitersAccess(companyId, redirectUrl) {
  const url = `/package_requestors/smart_recruiters/validate?company_id=${companyId}&redirect_url=${redirectUrl}`;
  return simpleAjaxDispatch(
    url,
    'validateSmartRecruitersAccess',
  );
}

export function smartRecruitersAuthorize(companyId, redirectUrl) {
  return simpleAjaxDispatch(
    '/package_requestors/smart_recruiters/authorize',
    'smartRecruitersAuthorize',
    { method: 'POST', json: { company_id: companyId, redirect_url: redirectUrl } },
  );
}
